import { ActionButton } from '@/components/ActionButton';
import { Alert } from '@/components/Alert';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ManageAppchainTab } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { ChainConfig } from '@/config';
import {
  useAppchainRuntimeInfo,
  useAppchainSudo,
} from '@/hooks/polkadot/appchain';
import { Box, Group, Text } from '@mantine/core';

interface Props {
  paraId: number;
  config: ChainConfig;
}

export function RuntimeAlert({ paraId, config }: Props) {
  const runtime = useAppchainRuntimeInfo(paraId, config);
  const { openAppchainSidebar } = useManageAppchain();
  const { isSudoOrProxyAvailable, isLoading } = useAppchainSudo(paraId, config);

  if (isLoading || !runtime || !runtime.version.behind) {
    return null;
  }

  return (
    <Alert>
      <Group justify="space-between" w={'100%'}>
        <Box>
          <Text size="xs" fw={600}>
            Upgrade required!
          </Text>
          <Text size="xs" c={'gray.6'}>
            Your runtime is {runtime.version.behind} versions behind. Upgrade to
            version {runtime.version.latest} for optimal performance.
          </Text>
        </Box>
        <ActionButton
          h={35}
          miw={'auto'}
          size="xs"
          bg={'blue.7'}
          withArrow={false}
          disabled={!isSudoOrProxyAvailable}
          tooltipLabel={
            'Ensure you have the sudo account or a proxy in your extension.'
          }
          isTooltipDisabled={isSudoOrProxyAvailable}
          onClick={() => {
            openAppchainSidebar({
              paraId,
              config,
              tab: ManageAppchainTab.Runtime,
            });
          }}
        >
          Upgrade
        </ActionButton>
      </Group>
    </Alert>
  );
}
